import React from "react";
import {
  CODE_OF_ETHICS_PDF,
  COMPLIANCE_POLICY_PDF,
  INFORMATION_SECURITY_POLICY_PDF,
  MONEY_LAUNDRY_PREVENTION_POLICY_PDF,
  PERSONAL_INVESTMENTS_POLICY_PDF,
  REFERENCE_FORM_PDF,
} from "../../constants/files/files.c";
import {
  INSTRAGRAM_LINK,
  LINKEDIN_LINK,
  WHATSAPP_LINK,
} from "../../constants/links/links.c";

// Defining the props expected for the main Footer component
interface FooterRenderProps {
  policies: Policy[];
  contactInfo: ContactInfo[];
  socialMedias: SocialMedia[];
}

/**
 * Footer Component
 *
 * The main footer component that includes sections for social media, policies,
 * contact information, and the footer copyright. It organizes the overall footer layout.
 *
 * @param {FooterRenderProps} props - Contains the policies, contact info, and social media links.
 * @returns {JSX.Element} - The JSX for the entire footer.
 */
const FooterRender: React.FC<FooterRenderProps> = ({
  policies,
  contactInfo,
  socialMedias,
}) => {
  return (
    <footer
      className="footer-bg footer-p pt-90"
      style={{ backgroundColor: "rgb(1, 50, 32)" }}
    >
      <div className="footer-top pb-70">
        <div className="container">
          <div className="row justify-content-between">
            <FooterContact socialMedias={socialMedias} />
            <FooterPolicies policies={policies} />
            <FooterContactInfo contactInfo={contactInfo} />
          </div>
        </div>
      </div>
      <FooterCopyright />
    </footer>
  );
};

/**
 * FooterSocial Component
 *
 * Renders the social media icons in the footer. Each icon is a clickable link that opens
 * the corresponding social media page in a new tab.
 *
 * @param {SocialMedia[]} socialMedias - List of social media platforms with URL and icon class.
 * @returns {JSX.Element} - The JSX for social media links in the footer.
 */
const FooterSocial: React.FC<{ socialMedias: SocialMedia[] }> = ({
  socialMedias,
}) => (
  <div className="footer-social mt-10">
    {socialMedias.map((socialMedia, index) => (
      <a
        key={index}
        href={socialMedia.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className={socialMedia.icon}></i>
      </a>
    ))}
  </div>
);

/**
 * FooterQuote Component
 *
 * Renders a quote in the footer, adding a personal and inspirational touch.
 *
 * @returns {JSX.Element} - The JSX for the quote section in the footer.
 */
const FooterQuote: React.FC = () => (
  <div className="footer-widget mb-30">
    <div className="f-contact">
      <p>
        <em>
          "Se alguém está sentado à sombra hoje é porque uma outra pessoa
          plantou uma árvore um dia"
        </em>
        <br />- Warren Buffett
      </p>
    </div>
  </div>
);

/**
 * FooterContact Component
 *
 * Combines the footer quote and social media links into a single section in the footer.
 *
 * @param {SocialMedia[]} socialMedias - List of social media platforms with URL and icon class.
 * @returns {JSX.Element} - The JSX for the contact section including the quote and social media.
 */
const FooterContact: React.FC<{ socialMedias: SocialMedia[] }> = ({
  socialMedias,
}) => (
  <div className="col-xl-4 col-lg-4 col-sm-6">
    <FooterQuote />
    <FooterSocial socialMedias={socialMedias} />
  </div>
);

/**
 * FooterPolicies Component
 *
 * Renders the corporate policies section in the footer. This includes a list of clickable links
 * to different policy documents such as Code of Ethics, Investment Policy, etc.
 *
 * @param {Policy[]} policies - List of corporate policies with name and URL.
 * @returns {JSX.Element} - The JSX for the policies section in the footer.
 */
const FooterPolicies: React.FC<{ policies: Policy[] }> = ({ policies }) => (
  <div className="col-xl-4 col-lg-4 col-sm-6">
    <div className="footer-widget mb-30">
      <div className="f-widget-title">
        <h2>Políticas Corporativas</h2>
      </div>
      <ul>
        {policies.map((policy, index) => (
          <li key={index}>
            <a href={policy.url} target="_blank" rel="noopener noreferrer">
              {policy.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

/**
 * FooterContactInfo Component
 *
 * Renders the contact information section in the footer. This includes the company's phone number,
 * email, and address, all of which can be clickable links (e.g., mailto, tel).
 *
 * @param {ContactInfo[]} contactInfo - List of contact information including icon, text, and optional URL.
 * @returns {JSX.Element} - The JSX for the contact information section in the footer.
 */
const FooterContactInfo: React.FC<{ contactInfo: ContactInfo[] }> = ({
  contactInfo,
}) => (
  <div className="col-xl-4 col-lg-4 col-sm-6">
    <div className="footer-widget mb-30">
      <div className="f-widget-title">
        <h2>Fale Conosco</h2>
      </div>
      <div className="f-contact">
        <ul>
          {contactInfo.map((contact, index) => (
            <li key={index}>
              <i className={`icon ${contact.icon}`}></i>
              <span>
                {contact.url ? (
                  <a href={contact.url}>{contact.text}</a>
                ) : (
                  contact.text
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

/**
 * FooterCopyright Component
 *
 * Renders the copyright notice in the footer. This is the bottom-most section of the footer.
 *
 * @returns {JSX.Element} - The JSX for the copyright section.
 */
const FooterCopyright: React.FC = () => (
  <div className="copyright-wrap">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12 text-center">
          <div className="copy-text">
            Copyright © Ostrya 2024. Todos os direitos reservados.
          </div>
        </div>
      </div>
    </div>
  </div>
);

interface ContactInfo {
  icon: string;
  text: string;
  url: string | null;
}

// Static Data
const contactInfo: ContactInfo[] = [
  {
    icon: "fal fa-phone",
    text: "+55 51 9998-4375",
    url: "tel:+555199984375",
  },
  {
    icon: "fal fa-envelope",
    text: "investimentos@ostrya.com.br",
    url: "mailto:investimentos@ostrya.com.br",
  },
  {
    icon: "fal fa-map-marker-check",
    text: "R. Tamandaré, 140, sala 901, - Novo Hamburgo - RS, 93410-150",
    url: null,
  },
];

interface Policy {
  name: string;
  url: string;
}

const policies: Policy[] = [
  { name: "Código de Ética", url: CODE_OF_ETHICS_PDF },
  { name: "Política de Investimentos", url: PERSONAL_INVESTMENTS_POLICY_PDF },
  { name: "Política de Compliance", url: COMPLIANCE_POLICY_PDF },
  {
    name: "Política de Segurança da Informação",
    url: INFORMATION_SECURITY_POLICY_PDF,
  },
  {
    name: "Política de Prevenção à Lavagem de Dinheiro",
    url: MONEY_LAUNDRY_PREVENTION_POLICY_PDF,
  },
  { name: "Formulário de Referência", url: REFERENCE_FORM_PDF },
];

interface SocialMedia {
  url: string;
  icon: string;
}

const socialMedias: SocialMedia[] = [
  { url: INSTRAGRAM_LINK, icon: "fab fa-instagram" },
  { url: WHATSAPP_LINK, icon: "fab fa-whatsapp" },
  { url: LINKEDIN_LINK, icon: "fab fa-linkedin" },
];

const Footer = () => {
  return (
    <FooterRender
      policies={policies}
      contactInfo={contactInfo}
      socialMedias={socialMedias}
    />
  );
};

export default Footer;
