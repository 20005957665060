/**
 * ====================================
 * SOCIAL MEDIA - LINKS
 * ====================================
 */
export const INSTRAGRAM_LINK =
  "https://www.instagram.com/ostrya.investimentos/";

export const LINKEDIN_LINK =
  "https://www.linkedin.com/company/ostrya-investimentos";

export const WHATSAPP_LINK =
  " https://wa.me/5551999984375?text=Ol%C3%A1%2C%20vim%20do%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20consultoria%20de%20investimentos";
