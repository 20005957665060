import React from "react";
import { MULTI_FAMILY_IMAGE } from "../../constants/images/images.c";
import { WHATSAPP_LINK } from "../../constants/links/links.c";
// Data for the Call to Action button
const callToActionData = {
  href: WHATSAPP_LINK,
  text: "Fale Com Um Especialista",
};

/**
 * BackgroundSection Component
 *
 * This component renders a background section with an image and overlay. It accepts
 * children components that will be displayed on top of the background.
 *
 * @param {React.ReactNode} children - The child components to be displayed inside the background section.
 * @returns {JSX.Element} - The JSX for the background section with an overlay.
 */
const BackgroundSection: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div
    className="py-5 position-relative"
    style={{
      backgroundImage: `url(${MULTI_FAMILY_IMAGE})`,
      backgroundSize: "cover",
      backgroundPosition: "center center",
    }}
  >
    <div
      className="overlay"
      style={{
        backgroundColor: "rgb(1, 50, 32)",
        opacity: 0.7,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    />
    {children}
  </div>
);

/**
 * TextContent Component
 *
 * This component renders the textual content for the Multi-Family Office section.
 * It includes the title, description, and a call to action button.
 *
 * @returns {JSX.Element} - The JSX for the text content section.
 */
const TextContent: React.FC = () => (
  <div className="container my-5 position-relative">
    <div className="row align-items-center">
      <div className="col-lg-6 col-md-12">
        <h6 className="text-white text-uppercase mb-2">FAMILY OFFICE</h6>
        <h2 className="text-white">
          Saiba mais sobre nossa atuação como um{" "}
          <span className="fw-bold text-oc-secondary">Multi-Family Office</span>{" "}
          exclusivo
        </h2>
        <p className="text-white">
          Nossa consultoria é especializada em investimentos e atuamos também
          como um Multi-Family Office. Trabalhamos com famílias que buscam uma
          administração completa de seus bens, oferecendo serviços de assessoria
          patrimonial, planejamento sucessório e gestão de investimentos. Nosso
          foco é garantir a segurança, preservação e crescimento do patrimônio,
          sempre com soluções personalizadas e estratégicas. Contamos com uma
          equipe altamente qualificada para proporcionar tranquilidade e
          eficiência na gestão financeira das famílias que atendemos, buscando
          sempre as melhores oportunidades no mercado.
        </p>
        <CallToAction />
      </div>
      <div className="col-lg-6 col-md-12 mb-4 mb-lg-0"></div>
    </div>
  </div>
);

/**
 * CallToAction Component
 *
 * Renders a call-to-action button with a link to WhatsApp for contacting a specialist.
 * The link and button text are defined in the `callToActionData` object.
 *
 * @returns {JSX.Element} - The JSX for the call-to-action button.
 */
const CallToAction: React.FC = () => (
  <div className="col-lg-4">
    <div
      className="cta-btn s-cta-btn wow fadeInRight animated"
      data-animation="fadeInDown animated"
    >
      <a
        href={callToActionData.href}
        target="_blank"
        rel="noopener noreferrer"
        className="btn ss-btn smoth-scroll"
      >
        {callToActionData.text}
      </a>
    </div>
  </div>
);

/**
 * MultiFamilyOfficeSection Component
 *
 * The main component that renders the Multi-Family Office section. It wraps the content inside
 * the `BackgroundSection` with an image and overlay.
 *
 * @returns {JSX.Element} - The JSX for the Multi-Family Office section.
 */
const MultiFamily: React.FC = () => (
  <BackgroundSection>
    <TextContent />
  </BackgroundSection>
);

export default MultiFamily;
